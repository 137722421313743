import React, { ChangeEvent, FocusEvent, forwardRef, useEffect, useState } from 'react'
import { NumberFormatOptions, NumberFormatter, NumberParser } from '@internationalized/number'
import { TextField, TextFieldProps } from '@mui/material'
import { NumberInputFormatOptions } from '@typedef/chatSteps/SimpleUserInputStep'
import useTranslations from '../../localisation/useTranslations'
import { TranslationKeys } from '../../localisation/translations'

type NumberInputProps = Omit<TextFieldProps, 'value' | 'onChange' | 'type'> & {
  TextFieldComponent: typeof TextField
  numberLocale: string
  value: string | number | null
  onValidInput: (value: number | null) => void
  placeholderTranslationKey: TranslationKeys
  formatOptions?: NumberInputFormatOptions
}

const exampleNumber = 10200.05

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      TextFieldComponent,
      value,
      onValidInput,
      numberLocale,
      formatOptions,
      placeholderTranslationKey,
      ...otherProps
    },
    ref,
  ) => {
    const localise = useTranslations()
    const [focused, setFocused] = useState(false)
    const [inputValue, setInputValue] = useState(
      value !== null ? formatNumber(value, numberLocale, formatOptions) : '',
    )

    useEffect(() => {
      // handle "reset values" for multi-input. Detect if `value` changes from parent and overwrite input value if it does
      const currentNumericValue = parseNumber(inputValue, numberLocale, formatOptions)
      if (value !== currentNumericValue) {
        setInputValue(value !== null ? formatNumber(value, numberLocale, formatOptions) : '')
      }
      // Specifically avoid triggering the effect on inputValue change
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formatOptions, numberLocale, value])

    const updateAndNotify = (input: string, updateInput: boolean = false) => {
      if (input === '') {
        setInputValue('')
        onValidInput(null)
      } else {
        const parser = new NumberParser(numberLocale, formatOptions ?? {})
        if (parser.isValidPartialNumber(input)) {
          setInputValue(input)
          let parsed = parseNumber(input, numberLocale, formatOptions)
          if (!isNaN(parsed)) {
            if (updateInput) {
              const formattedNumber = formatNumber(parsed, numberLocale, {
                ...(formatOptions ?? {}),
                style: 'decimal',
              })

              // since parseNumber does not apply rounding from maximumFractionDigits,
              // reparse the formatted number again to apply correct rounding to numeric result
              parsed = parseNumber(formattedNumber, numberLocale, formatOptions)
              setInputValue(formattedNumber)
            }
            onValidInput(parsed)
          }
        }
      }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateAndNotify(event.target.value)
    }

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      updateAndNotify(event.target.value, true)
      setFocused(false)
      otherProps.onBlur?.(event)
    }

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      otherProps.onFocus?.(event)
    }

    let placeholder = otherProps.placeholder
    if (!placeholder) {
      placeholder = localise(placeholderTranslationKey, {
        value: formatNumber(formatOptions?.placeholderValue ?? exampleNumber, numberLocale, {
          minimumFractionDigits: 2,
        }),
      })
    }

    let finalValue = inputValue
    if (!focused && (value === 0 || !!value)) {
      finalValue = formatNumber(value, numberLocale, formatOptions)
    }

    return (
      <TextFieldComponent
        ref={ref}
        {...otherProps}
        type='text'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={finalValue}
        placeholder={placeholder}
      />
    )
  },
)

export const parseNumber = (
  input: string,
  numberLocale: string,
  options?: NumberFormatOptions,
): number => {
  if (input === '') {
    return NaN
  }
  let parserInput = input
  let parserOptions = { ...(options ?? {}) }
  if (parserOptions.style === 'percent') {
    // Work around floating point imprecision
    // For 'percent' style, NumberParser returns 0.07 for 7%, which we have to multiply by 100 to get 7
    // But due to floating-point imprecision, 0.07 * 100 === 7.000000000000001 in javascript
    // Instead, parse as normal decimal and manually remove percentage signs
    parserOptions.style = 'decimal'
    parserInput = parserInput.replaceAll('%', '')
  }
  const parser = new NumberParser(numberLocale, parserOptions)
  const parsed = parser.parse(parserInput)
  if (!isNaN(parsed)) {
    return parsed
  }
  return NaN
}

export const formatNumber = (
  input: string | number,
  numberLocale: string,
  options?: NumberFormatOptions,
): string => {
  const inputNumber = typeof input === 'string' ? parseFloat(input) : input
  if (isNaN(inputNumber)) {
    return ''
  }
  return new NumberFormatter(numberLocale, options ?? {}).format(
    options?.style === 'percent' ? inputNumber / 100 : inputNumber,
  )
}
